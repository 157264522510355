<template>
  <base-layout title="Create Exam">
    <ion-grid fixed>
      <form @submit.prevent="confirmCreate()" action="">
        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item v-if="validationError" lines="none">
                <ion-label className="ion-text-wrap">
                  <ion-text color="danger">
                    <p v-for="error of v$.$errors" :key="error.$uid">
                      <ion-icon
                        :ios="alertCircleOutline"
                        :md="alertCircleSharp"
                        size="small"
                        style="vertical-align: middle"
                      ></ion-icon
                      >{{ error.$property }}: {{ error.$message }}
                    </p></ion-text
                  >
                </ion-label>
              </ion-item>

              <ion-item :class="{ 'color-danger': v$.name.$error }">
                <ion-label position="floating">Name</ion-label>
                <ion-input type="text" v-model="name"></ion-input>
              </ion-item>

              <ion-item :class="{ 'color-danger': v$.description.$error }">
                <ion-label position="floating">Description</ion-label>
                <ion-textarea type="text" v-model="description"></ion-textarea>
              </ion-item>

              <ion-item :class="{ 'color-danger': v$.exam_type_id.$error }">
                <ion-label position="floating">Exam Type</ion-label>
                <ion-select v-model="exam_type_id" :value="exam_type_id">
                  <ion-select-option
                    v-for="exam_type of exam_types"
                    :key="exam_type.id"
                    :value="exam_type.id"
                  >
                    {{ exam_type.name }}
                  </ion-select-option>
                </ion-select>
              </ion-item>

              <ion-item :class="{ 'color-danger': v$.exam_start_date.$error }">
                <ion-label position="floating">Start date</ion-label>
                <ion-datetime v-model="exam_start_date"></ion-datetime>
              </ion-item>

              <ion-item :class="{ 'color-danger': v$.exam_end_date.$error }">
                <ion-label position="floating">Completion date</ion-label>
                <ion-datetime v-model="exam_end_date"></ion-datetime>
              </ion-item>

              <ion-item>
                <ion-label position="floating">No of slots</ion-label>
                <ion-select
                  v-model="slots"
                  :value="slots"
                  @ionChange="setSlots($event)"
                >
                  <ion-select-option value="1">1</ion-select-option>
                  <ion-select-option value="2">2</ion-select-option>
                  <ion-select-option value="3">3</ion-select-option>
                  <ion-select-option value="4">4</ion-select-option>
                  <ion-select-option value="5">5</ion-select-option>
                  <ion-select-option value="6">6</ion-select-option>
                </ion-select>
              </ion-item>

              <ion-item v-for="(n, index) in parseInt(slots)" :key="index">
                <div
                  slot="start"
                  :class="{
                    'color-danger':
                      v$.slot_times.$error &&
                      v$.slot_times.$each.$response.$data[index].start.$error,
                  }"
                >
                  <ion-label position="floating"
                    >Slot {{ index + 1 }} from:</ion-label
                  >
                  <ion-datetime
                    display-format="h:mm A"
                    picker-format="h:mm A"
                    v-model="slot_times[index].start"
                  ></ion-datetime>
                </div>
                <div
                  slot="end"
                  class="ion-no-margin"
                  :class="{
                    'color-danger':
                      v$.slot_times.$error &&
                      v$.slot_times.$each.$response.$data[index].end.$error,
                  }"
                >
                  <ion-label position="floating"
                    >Slot {{ index + 1 }} end:</ion-label
                  >
                  <ion-datetime
                    display-format="h:mm A"
                    picker-format="h:mm A"
                    v-model="slot_times[index].end"
                  ></ion-datetime>
                </div>
              </ion-item>

              <ion-item>
                <ion-label>Select all classes</ion-label>
                <ion-checkbox
                  slot="end"
                  style="margin-bottom: 0; margin-top: 0"
                  @ionChange="toggleAll($event)"
                ></ion-checkbox>
              </ion-item>

              <ion-item :class="{ 'color-danger': v$.class_ids.$error }">
                <ion-label position="floating">Classes</ion-label>
                <ion-select v-model="class_ids" multiple>
                  <ion-select-option
                    v-for="classe of classes"
                    :key="classe.section_standard_id"
                    :value="classe.section_standard_id"
                  >
                    {{ classe.standard.name }} - {{ classe.section.name }}
                  </ion-select-option>
                </ion-select>
              </ion-item>

              <ion-item :class="{ 'color-danger': v$.full_mark.$error }">
                <ion-label position="floating">Full mark</ion-label>
                <ion-input type="number" v-model.number="full_mark"></ion-input>
              </ion-item>

              <ion-item :class="{ 'color-danger': v$.pass_mark.$error }">
                <ion-label position="floating">Pass mark</ion-label>
                <ion-input type="number" v-model.number="pass_mark"></ion-input>
              </ion-item>

              <ion-item
                :class="{ 'color-danger': v$.negative_percentage.$error }"
              >
                <ion-label position="floating">Negative Percentage</ion-label>
                <ion-input
                  type="number"
                  v-model.number="negative_percentage"
                ></ion-input>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button type="submit" expand="full" class="ion-margin-vertical">
              Create Exam
            </ion-button>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
  </base-layout>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonTextarea,
  IonButton,
  IonText,
  IonCheckbox,
  loadingController,
  alertController,
  toastController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import {
  required,
  integer,
  maxLength,
  minValue,
  maxValue,
  helpers,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonTextarea,
    IonButton,
    IonText,
    IonCheckbox,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      name: null,
      description: null,
      exam_type_id: null,
      exam_start_date: null,
      exam_end_date: null,
      class_ids: null,
      full_mark: 100,
      pass_mark: 30,
      negative_percentage: 0,

      slots: "1",
      slot_times: [{ start: null, end: null }],

      alertCircleOutline,
      alertCircleSharp,

      validationError: null,
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  validations() {
    return {
      name: {
        required: helpers.withMessage("is required", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      description: {
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      exam_type_id: {
        integer: helpers.withMessage("can only be integers", integer),
      },

      exam_start_date: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },

      exam_end_date: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
        // Custom Validator
        minValue: helpers.withMessage(
          "cannot be less than start date",
          function minValue(val) {
            return new Date(val) >= new Date(this.exam_start_date);
          }
        ),
      },

      slot_times: {
        $each: helpers.forEach({
          start: {
            required: helpers.withMessage("cannot be empty", required),
          },
          end: {
            required: helpers.withMessage("cannot be empty", required),
            minValue: helpers.withMessage(
              "cannot be less than start date",
              function minValue(val, slot) {
                return new Date(val) >= new Date(slot.start);
              }
            ),
          },
        }),
      },

      class_ids: {
        required: helpers.withMessage("is required", required),
      },

      full_mark: {
        required: helpers.withMessage("is required", required),
        minValue: helpers.withMessage("Minimum 10 marks", minValue(10)),
        maxValue: helpers.withMessage("Maximum 200 marks", maxValue(200)),
      },

      pass_mark: {
        required: helpers.withMessage("is required", required),
        minValue: helpers.withMessage("Minimum 1 mark", minValue(1)),
        maxValue: helpers.withMessage(
          "Cannot be more than full mark",
          function minValue(val) {
            return this.full_mark >= val;
          }
        ),
      },

      negative_percentage: {
        required: helpers.withMessage("is required", required),
        minValue: helpers.withMessage("Minimum 0 percent", minValue(0)),
        maxValue: helpers.withMessage("Maximum 400 percent", maxValue(400)),
      },
    };
  },

  computed: {
    exam_types() {
      return this.$store.getters["attribute/examTypes"];
    },
    classes() {
      return this.$store.getters["class/allResources"];
    },

    date_range() {
      let range = moment.range(
        moment(this.exam_start_date),
        moment(this.exam_end_date)
      );

      return Array.from(range.by("days")).map((date) => date.format("YYYY-MM-DD"));
    },

    formated_slot_times() {
      return this.slot_times.map((slot) => {
        return {
          start: moment(slot.start).format("HH:mm"),
          end: moment(slot.end).format("HH:mm"),
        };
      });
    },

    exam_schedules() {
      let date_range = this.date_range;
      let slot_times = this.formated_slot_times;

      let exam_schedules = [];

      date_range.forEach((date) => {
        slot_times.forEach((slot) => {
          exam_schedules.push({
            start: moment(date + " " + slot.start).format(
              "YYYY-MM-DD HH:mm"
            ),
            end: moment(date + " " + slot.end).format("YYYY-MM-DD HH:mm"),
          });
        });
      });

      return exam_schedules;
    },

    exam_schedule_start() {
      let exam_schedules = this.exam_schedules;
      return exam_schedules.map((exam_schedule) => exam_schedule.start);
    },

    exam_schedule_end() {
      let exam_schedules = this.exam_schedules;
      return exam_schedules.map((exam_schedule) => exam_schedule.end);
    },
  },

  created() {
    this.fetchExamTypes();
    this.fetchClasses();
  },

  methods: {
    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    toaster(message) {
      const toast = toastController.create({
        message,
        duration: 3000,
        color: "primary",
      });

      return toast;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmCreate() {
      const header = `Confirm`;
      const message = "Are you sure you want to create exam";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.createExam();
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async createExam() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        name: this.name,
        description: this.description,
        exam_type_id: this.exam_type_id,
        exam_start_date: moment(this.exam_start_date).format("YYYY-MM-DD"),
        exam_end_date: moment(this.exam_end_date).format("YYYY-MM-DD"),
        class_ids: this.class_ids,
        exam_schedules: this.exam_schedules,
        full_mark: this.full_mark,
        pass_mark: this.pass_mark,
        negative_percentage: this.negative_percentage,
      };

      const authenticating = await this.loader("Saving changes...");

      await authenticating.present();

      try {
        await this.$store.dispatch("exam/createResource", data);
        authenticating.dismiss();
        this.$router.replace({ name: "exam.list" });
        const toast = await this.toaster("Exam created successfully");
        await toast.present();
      } catch (error) {
        authenticating.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        await alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },

    async toggleAll(event) {
      if (event.detail.checked) {
        this.selectAllClasses();
      } else {
        this.class_ids = [];
      }
    },

    async selectAllClasses() {
      this.class_ids = [];
      this.classes.forEach((classroom) => {
        this.class_ids.push(classroom.section_standard_id);
      });
    },

    async setSlots(event) {
      const no_of_slots = parseInt(event.detail.value);
      const existing_no_of_slots = this.slot_times.length;
      const diff = no_of_slots - existing_no_of_slots;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          this.slot_times.push({
            start: null,
            end: null,
          });
        }
      } else {
        for (let i = 0; i < Math.abs(diff); i++) {
          this.slot_times.pop();
        }
      }
    },

    async fetchExamTypes() {
      await this.$store.dispatch("attribute/examTypes");
    },

    async fetchClasses() {
      await this.$store.dispatch("class/allResources");
    },
  },
};
</script>

<style scoped></style>